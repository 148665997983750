import React from "react";
import "./management.scss";
import person from "./img/person.png";

const Management = () => {
	return (
		<section className="full-width management">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
						<h2>Руководство</h2>
					</div>
					<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
						<div className="management__award">
							<img
								alt="Эйдлин Денис Григорьевич"
								className="management__award__image"
								src={person}
							/>
							<div className="management__award__content">
								<h4>Эйдлин Денис Григорьевич</h4>
								<p>Генеральный директор АО&nbsp;«Транспортная&nbsp;карта»</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Management;
